import { Link } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThanksPage = ({ location }) => {
  return (
    <Layout location={location}>
        <article>
          <div className="container md:mx-auto max-w-page py-4 md:py-8">
            <h1 className="text-3xl md:text-4xl font-alegreya">Message sent</h1>
            <p className="text-gray-600 md:text-lg"><strong>Thank you for reaching out.</strong></p>
            <p className="text-gray-600 md:text-lg">I will get back to by your preferred contact method using the details you have supplied as soon as I can.</p>
            <p className="text-gray-600 md:text-lg">I usually try to respond with in 48 hours but at certain times this may be impossible.</p>
            <p className="text-gray-600 md:text-lg">Please return to the <Link to={"/"} className="text-pink-700 underline md:no-underline md:hover:underline">Home page</Link>.</p>
          </div>
        </article>
    </Layout>
  )
}

export const Head = () => (
  <Seo title="Get in touch - Message sent" />
)

export default ThanksPage